import BaseBean from "@/utils/BaseBean";

export interface IT001CardDataObj {
    utilInst:T001CardUtil
    formRef:any
    disabled:boolean
    refMap:Map<string,any>
    compParams: any
    form:any
    rules:TFormRule
    otherParams:any
}
export default class T001CardUtil extends BaseBean{
    public dataObj:IT001CardDataObj;

    constructor(proxy:any,dataObj:IT001CardDataObj) {
        super(proxy);
        this.dataObj=dataObj
    }
    //构建下拉数据
    public async buildSelectData():Promise<void>{

    }
}